import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RedirectFromRootGuard implements CanActivate {

  constructor(private router: Router){}
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    return this.validateRedirect(route)
  }

  private validateRedirect(route: ActivatedRouteSnapshot): boolean {

    console.log('route.data',route.data);
      
    const  redirectURL: string  = route.data['redirectURL']
    if(redirectURL !== undefined && redirectURL != null && redirectURL.trim().length > 0)
      this.router.navigateByUrl(redirectURL)
      
    return true;
  }
  
}
