import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {catchError} from 'rxjs/operators';
import { ActiveSessionService } from '../services/active-session.service';

@Injectable()
export class BearerTokenInterceptor implements HttpInterceptor {

  constructor( private activeSessionService: ActiveSessionService ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let req = request;

    if(this.activeSessionService.SessionExists()){
      req = request.clone({
        setHeaders: {
          authorization: this.activeSessionService.BearerToken()
        }
      })
    }
    
    return next.handle(req).pipe(
      catchError((error) => {
        console.log('Error capturado por interceptor')
        console.log('interceptor -->', error)
        return throwError(() => error)
      })
    )
  }
}
