import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { UserLogin } from '../models/usuario-dto';

@Injectable({
  providedIn: 'root'
})
export class ActiveSessionService {

  private redirectToLogin: string = environment.irLogin
  private userData!: UserLogin

  constructor( private cookieService: CookieService, private router: Router) { }

  private redirect(flag: boolean): void {
    if(!flag) this.router.navigateByUrl(this.redirectToLogin)
  }

  private _activeSesion(): boolean {
    let cookies: boolean = false
    try
    {
      if(this.cookieService.check('userLogin')){
        const userLogin: UserLogin = JSON.parse(this.cookieService.get('userLogin'))    
        if (userLogin && userLogin.token != null  && userLogin.token.trim().length > 0) cookies = true
      }      
    }
    catch(err)
    {
      console.error(err)
    }
    return cookies
  }

  private validateSession(): void {
    
    let cookies: boolean = this._activeSesion()
    try
    {
      if(cookies) this.userData = JSON.parse(this.cookieService.get('userLogin'))
    }
    catch(err)
    {
      console.error(err)
      cookies = false
    }
    this.redirect(cookies)
  }

  public UUID(): string {
    this.validateSession()
    return this.userData.uuid
  }
  
  public JsonUUID(): any {
    this.validateSession()
    return { UUID: this.userData.uuid } 
  }

  public Token(): string {
    this.validateSession()
    return this.userData.token
  }
  
  public BearerToken(): string {
    this.validateSession()
    return `Bearer ${this.userData.token}` 
  }

  public Rol(): number {
    this.validateSession()
    return this.userData.rol
  }
  
  public CommerceStatusId(): number {
    this.validateSession()
    return this.userData.commerceStatusId
  }

  public Name(): string {
    this.validateSession()
    return this.userData.name
  }
  
  public LastName(): string {
    this.validateSession()
    return this.userData.lastName
  }
  
  public SecondLastName(): string {
    this.validateSession()
    return this.userData.secondLastName
  }
  
  public FullName(): string {
    this.validateSession()
    return `${this.userData.name} ${this.userData.lastName} ${this.userData.secondLastName}`
  }

  public CommerceName(): string {
    this.validateSession()
    return this.userData.commerceName
  }

  public SessionExists(): boolean {
    return this._activeSesion()
  }

  public IsNotSessionRedirectToLogin(): void {
    this.validateSession()
  }
}
