import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SecurityGuard } from './shared/guards/security.guard';
import { RedirectFromRootGuard } from './shared/guards/redirect-from-root.guard';

const routes: Routes = [
  {
    path: 'inicio',
    loadChildren: () => import('./inicio/inicio.module').then( m => m.InicioModule )
  },
  {
    path: 'aviso-de-privacidad',
    loadChildren: () => import('./inicio/inicio.module').then( m => m.InicioModule )
  },
  {
    path: 'eliminatcuenta',
    loadChildren: () => import('./autentificacion/autentificacion.module').then( m => m.AutentificacionModule ),
    data:{ redirectURL: '/auth/eliminar-mi-cuenta' },
    canActivate: [RedirectFromRootGuard]
  },
  {
    path: 'eliminar',
    loadChildren: () => import('./autentificacion/autentificacion.module').then( m => m.AutentificacionModule )
  },
  {
    path: 'auth',
    loadChildren: () => import('./autentificacion/autentificacion.module').then( m => m.AutentificacionModule )
  },
  {
    path: 'confirmar',
    loadChildren: () => import('./autentificacion/autentificacion.module').then( m => m.AutentificacionModule )
  },
  {
    path: 'comercio',
    loadChildren: () => import('./comercio/comercio.module').then( m => m.ComercioModule ),
    canActivate: [SecurityGuard]
  },
  // {
  //   path: 'empresa',
  //   loadChildren: () => import('./empresa/empresa.module').then( m => m.EmpresaModule ),
  //   canActivate: [SecurityGuard]
  // },
  // {
  //   path: 'empleado',
  //   loadChildren: () => import('./empleado/empleado.module').then( m => m.EmpleadoModule ),
  //   canActivate: [SecurityGuard]
  // },
  {
    path: 'producto',
    loadChildren: () => import('./producto/producto.module').then( m => m.ProductoModule ),
    canActivate: [SecurityGuard]
  },
  {
    path: 'solicitud',
    loadChildren: () => import('./solicitud/solicitud.module').then( m => m.SolicitudModule ),
    canActivate: [SecurityGuard]
  },
  {
    path: 'reporte',
    loadChildren: () => import('./reporte/reporte.module').then( m => m.ReporteModule ),
    canActivate: [SecurityGuard]
  },
  {
    path: 'configuracion',
    loadChildren: () => import('./config/config.module').then( m => m.ConfigModule ),
    canActivate: [SecurityGuard]
  },
  {
    path: '',
    loadChildren: () => import('./inicio/inicio.module').then( m => m.InicioModule )
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
