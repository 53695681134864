import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { BearerTokenInterceptor } from './shared/interceptors/bearer-token.interceptor';
import { PushNotificationService } from './shared/services/push-notification.service';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = []; //routes in here

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    RouterModule.forRoot(routes, { useHash: true })
  ],
  providers: [
    Title,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BearerTokenInterceptor,
      multi: true
    },
    PushNotificationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
