// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//Se lanza a producción 230815

export const environment = {
  production: false,
  envName: "Development",
  urlLogo: "./assets/img/logo.png",
  urlApiUrbanChef: "https://api-test.urbanchef.mx/api/",
  //urlApiUrbanChef: "https://localhost:5001/api/",
  urlWA: "https://wa.me/",
  duracionHrasBearerToken: 23,
  defaultAvatarCustomer: "assets/img/customer-avatar.png",
  irLogin: "/auth/login",
  storageImagen:{
    commerces: "commerce/img/",
    products: "product/img/",
    categories: "category/img/",
    modalities: "modality/img/",
    companies: "Company/img/",
    plats: "Company/Plant/img/",
    employees: "Employee/img/",
    urbanAllServices: "",
    app: "",
  },
  audio:{
    newOrder: "/assets/new-order.mpeg",
    newMessage: "/assets/new-message.mp3",
    arriveRestaurant: "/assets/arrive-restaurant.mp3",
    statusColor: "/assets/change-order-status-color.mp3"
  },
  firebase : {
    apiKey: "AIzaSyCl6r8m2osBi6g4eko876E_rsH4SmfWfNw",
    authDomain: "urban-chef-320422.firebaseapp.com",
    projectId: "urban-chef-320422",
    storageBucket: "urban-chef-320422.appspot.com",
    messagingSenderId: "799022987086",
    appId: "1:799022987086:web:4be98c8e7ec42451be101f",
    measurementId: "G-KL7EWCG0NZ",
    vapidKey: "BNqFt7uAGiianajc6Qq-tkq5bcxId2ydQss5a0LMS41yvAn4dCCig-N0pPpttxYyCqZqWAnwZSNw8BEWYUPwb74"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
