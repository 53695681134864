import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActiveSessionService } from '../services/active-session.service';

@Injectable({
  providedIn: 'root'
})
export class SecurityGuard implements CanActivate {

  private redirectToLogin: string = environment.irLogin
  
  constructor(private activeSessionService: ActiveSessionService, private router: Router){}

  private redirect(flag: boolean): void {

    if(!flag) this.router.navigateByUrl(this.redirectToLogin)
  }

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
      const cookies: boolean = this.activeSessionService.SessionExists()

      this.redirect(cookies)

      return cookies
  }
  
}
