import { Injectable } from '@angular/core';

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
initializeApp(environment.firebase);



@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  message: any = null;

  constructor() { }

  requestPermission = () => {
    return new Promise(async (resolve, reject) => {

      const permisis = await Notification.requestPermission()

      if(permisis === 'granted'){
        const messaging = getMessaging()
        getToken(messaging, { vapidKey: environment.firebase.vapidKey })
        .then((currentToken) => {
          if (currentToken) {
            resolve(currentToken)
          } else {
            console.log('No registration token available. Request permission to generate one.')
            reject(new Error('Para activar las notificaciones, se requiere que otrogue los permisos de notificaciones.'))
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err)
          reject(new Error('Ocurrió un error al recuperar el token.'))
        })
      }else{
        reject(new Error('Para activar las notificaciones, se requiere que otorgue los permisos de notificaciones push.'))
      }
    })
  }

  private messagingObservable = new Observable<any>( observe=>{
    const messaging = getMessaging()
    onMessage(messaging, (payload) => {
      observe.next(payload)
    })
  })

  receiveNotification(){
    return this.messagingObservable
  }







  // requestPermission() {
  //   const messaging = getMessaging();
  //   getToken(messaging,
  //    { vapidKey: environment.firebase.vapidKey}).then(
  //      (currentToken) => {
  //        if (currentToken) {
  //          console.log("Hurraaa!!! we got the token.....");
  //          console.log(currentToken);
  //        } else {
  //          console.log('No registration token available. Request permission to generate one.');
  //        }
  //    }).catch((err) => {
  //       console.log('An error occurred while retrieving token. ', err);
  //   });
  // }

  // listen() {
  //   const messaging = getMessaging();
  //   onMessage(messaging, (payload) => {
  //     console.log('Message received. ', payload);
  //     this.message = payload;
  //   });
  // }








  // requestPermission = ()=>{
  //   return new Promise(async (resolve, reject)=>{
  //     const permisis = await Notification.requestPermission()

  //     if(permisis === 'granted'){
  //       const tokenFirebase = await this.messagingFirebase.getToken()
  //       resolve(tokenFirebase)
  //     }else{
  //       reject(new Error('El usuario denego el permiso'))
  //     }
  //   })
  // }

  // private messagingObservable = new Observable( observe=>{
  //   this.messagingFirebase.onMessage( (payload: any)=>{
  //     observe.next(payload)
  //   })
  // })

  // receiveNotification(){
  //   return this.messagingObservable
  // }
}
